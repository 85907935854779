import {
  AppListing,
  AppValidation,
  DeserializedAppCurrentUserMeta,
  DeserializedAppData,
  DeserializedAppReview,
  ListingData,
} from '@api';

import { captchaDecorator } from '@shared/helpers/captcha';
import { deserialize, JsonApiObject } from '@shared/helpers/jsonapi';

import Api, { ApiOptions } from './ApiProvider';

export type GetAppValidationSearchParams = {
  offset?: number;
  limit?: number;
};

export const DEFAULT_APP_VALIDATION_VOTERS_LIMIT = 20;

export const getAppValidation = (
  id: string,
  options?: ApiOptions<{ searchParams: GetAppValidationSearchParams }>,
) => {
  const optionsWithDefaults: ApiOptions<{ searchParams: GetAppValidationSearchParams }> = {
    ...options,
    searchParams: {
      limit: DEFAULT_APP_VALIDATION_VOTERS_LIMIT,
      offset: 0,
      ...options?.searchParams,
    },
    cache: 'no-store',
  };

  return Api.get(
    `v2/store/application/${id}/validation`,
    optionsWithDefaults,
  ).json<AppValidation>();
};

export const getApp = async ({
  searchParams: { appId, ...searchParams },
}: ApiOptions<{ searchParams: { appId: string } }>) => {
  const rawApp = await Api.get(`v2/store/application/${appId}`, {
    searchParams,
  }).json<JsonApiObject>();

  return deserialize<DeserializedAppData>(rawApp);
};

export const getAppForLIsting = (appId: string, options?: ApiOptions) => {
  return Api.get(`v2/store/creator/apps/${appId}`, options).json<AppListing>();
};

export const listingApp = (options?: ApiOptions<{ json: ListingData }>) =>
  Api.post('v2/store/creator/apps', options).json<{ appId: string; id: number }>();

export const editApp = (appId: string, options?: ApiOptions<{ json: ListingData }>) =>
  Api.put(`v2/store/creator/apps/${appId}`, options).json<{ appId: string; id: number }>();

export const getAppCurrentUserMeta = (appId: string, options?: ApiOptions) => {
  return Api.get(`v2/store/app/${appId}/meta`, options).json<DeserializedAppCurrentUserMeta>();
};

export const getAppShareLink = (appId: string, options?: ApiOptions) =>
  Api.get(`v2/store/app/${appId}/share-link`, options).json<{ sharingLink: string }>();

export const addAppLike = ({ appId }: { appId: number }) =>
  Api.post(`v2/store/application/${appId}/like`).json<{
    id: number;
    user_id: number;
    app_id: number;
  }>();

export const removeAppLike = ({ appId }: { appId: number }) =>
  Api.delete(`v2/store/application/${appId}/like`).json<{ data: string }>(); // api returns just string, but we transform it in object

export const addTrustReview = captchaDecorator(
  (
    options: ApiOptions<{
      json: { id: number; review_rating: number; review_title: string; review_text: string };
    }>,
  ) =>
    Api.post(`v2/store/application/${options.json.id}/trusted-reviews`, options).json<{
      id: number;
      user_id: number;
      user_name: string;
      app_id: number;
      rating: number;
      text: string;
      title: string;
      create_time: string;
      deleted: boolean;
      visible: boolean;
      appreciated: boolean;
    }>(),
  'addTrustReview',
);

export type GetAppReviewsSearchParams = {
  limit?: number;
  offset?: number;
  appId: string;
};

export const getAppReviews = async ({
  searchParams: { appId, ...searchParams },
}: ApiOptions<{
  searchParams: GetAppReviewsSearchParams;
}>) => {
  const data = await Api.get(`v2/store/application/${appId}/reviews`, {
    searchParams,
  }).json<JsonApiObject>();

  return deserialize<DeserializedAppReview[]>(data);
};

export const addAppReview = captchaDecorator(
  (
    options: ApiOptions<{
      json: { id: number; review_rating: number; review_title: string; review_text: string };
    }>,
  ) => Api.post(`v2/store/application/${options.json.id}/reviews`, options).json(),
  'addAppReview',
);

export const addAppSuggestion = captchaDecorator(
  (
    options: ApiOptions<{
      json: { id: number; suggestion_title: string; suggestion_text: string };
    }>,
  ) => Api.post(`v2/store/application/${options.json.id}/suggestions`, options).json(),
  'addAppSuggestion',
);

export const checkIsValidName = (
  options: ApiOptions<{
    searchParams: {
      app_name: string;
    };
  }>,
) => {
  return Api.get('v2/store/app/check/name', options).json<{ name_busy: boolean }>();
};

export const subscribeToSaleReminder = async (
  options: ApiOptions<{
    json: {
      type: string;
      entityId: string;
    };
  }>,
) => {
  return Api.post('v2/notifications/subscribe', options).json<{
    success: boolean;
    message: string;
  }>();
};
